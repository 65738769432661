export interface EmailMessage {
    from: Recipient;
    to: Recipient[];
    content: Content;
}

export interface Recipient {
    name: string;
    address: string;
}

export interface Content {
    subject: string;
    bodyHtml: string;
}

export const addNewsLetter = (emailadress: string) : Promise<Response> =>
{
    const mail: EmailMessage = {
        from: {
            name: "website nieuwsbrief",
            address: "info@tiana.nu",
        },
        to:[
            {name:"info@tiana.nu", address: "info@tiana.nu"}
        ],
        content: {
            subject: "website aanmelding nieuwsbrief",
            bodyHtml: "<h2>Aanmelding nieuwsbrief vanaf website</h2><br/>" + emailadress
        }
    }
    return sendMail(mail);
}


export const sendMail = (message: EmailMessage) : Promise<Response> =>{
    
    return fetch("https://email.tiana.nu/api/email",{
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(message)
    });
}

